<div style="height:inherit" class="ui-overlay" *ngIf="disabled"></div>
<div style="height:inherit">
    <dynamic-save-changes-button *ngIf="actionButtonStructure.componentSettings.buttonType === 'save' || actionButtonStructure.componentSettings.buttonType === 'saveredirect'"
                                 [dynamicGridComponents]="dynamicGridComponents"
                                 [dynamicFormComponents]="dynamicFormComponents"
                                 [actionButtonStructure]="actionButtonStructure"
                                 [disabled]="disabled"
                                 [pageName]="pageName"
                                 [dataSourceName]="dataSourceName">
    </dynamic-save-changes-button>
    <div *ngIf="actionButtonStructure.componentSettings.buttonType === 'redirect'" style="height:inherit">
        <div style="padding:0%;height:inherit;" class="col-md-12 action-buttons text-right">
            <button [buttonBusy]="false" (click)="goToLink()" class="btn btn-primary">{{l(actionButtonStructure.componentSettings.actionButtonLabelKey)}}</button>
        </div>
    </div>
    <div *ngIf="actionButtonStructure.componentSettings.buttonType === 'search'" style="height:inherit">
        <div style="padding:0%;height:inherit;" class="col-md-12 action-buttons text-right">
            <button [buttonBusy]="false" (click)="search()" class="btn btn-primary">{{l(actionButtonStructure.componentSettings.actionButtonLabelKey)}}</button>
        </div>
    </div>
    <div *ngIf="actionButtonStructure.componentSettings.buttonType === 'clear'" style="height:inherit">
        <div style="padding:0%;height:inherit;" class="col-md-12 action-buttons text-right">
            <button [buttonBusy]="false" (click)="clear()" class="btn btn-primary">{{l(actionButtonStructure.componentSettings.actionButtonLabelKey)}}</button>
        </div>
    </div>
    <dynamic-reset-password-button *ngIf="actionButtonStructure.componentSettings.buttonType === 'reset'"
                                   [dynamicFormComponents]="dynamicFormComponents"
                                   [actionButtonStructure]="actionButtonStructure">
    </dynamic-reset-password-button>
    <dynamic-generate-password-button *ngIf="actionButtonStructure.componentSettings.buttonType === 'generate'"
                                      [dynamicFormComponents]="dynamicFormComponents"
                                      [actionButtonStructure]="actionButtonStructure">
    </dynamic-generate-password-button>
    <dynamic-generate-activation-code-button *ngIf="actionButtonStructure.componentSettings.buttonType === 'actcode'"
                                             [dynamicFormComponents]="dynamicFormComponents"
                                             [actionButtonStructure]="actionButtonStructure">
    </dynamic-generate-activation-code-button>
    <dynamic-activate-user-button *ngIf="actionButtonStructure.componentSettings.buttonType === 'activate'"
                                  [dynamicFormComponents]="dynamicFormComponents"
                                  [actionButtonStructure]="actionButtonStructure">
    </dynamic-activate-user-button>
    <dynamic-upload-button *ngIf="actionButtonStructure.componentSettings.buttonType === 'upload'"
                           [dynamicGridComponents]="dynamicGridComponents"
                           [actionButtonStructure]="actionButtonStructure">
    </dynamic-upload-button>
    <dynamic-validate-intacct *ngIf="actionButtonStructure.componentSettings.buttonType === 'validateintacct'"
                              [dynamicFormComponents]="dynamicFormComponents"
                              [actionButtonStructure]="actionButtonStructure">
    </dynamic-validate-intacct>
    <dynamic-import-data *ngIf="actionButtonStructure.componentSettings.buttonType === 'importdata'"
                         [dynamicFormComponents]="dynamicFormComponents"
                         [actionButtonStructure]="actionButtonStructure">
    </dynamic-import-data>
</div>